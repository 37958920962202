// 学校简介
<template>
  <div class="aboutus">
    <TabPageLayout activeCode="yujianyizhong">
      <div class="container profile">
        <div class="div_mod div_mod1">
          <div
            style="margin-top: 0.4rem"
            v-for="(block, index) in blocks"
            :key="block.id"
          >
            <div class="div_tit">
              <h2>{{ block.title }}</h2>
            </div>

            <!-- 学校简介 -->
            <div v-if="block.code === 'AboutTheSchool'">
              <!-- 栏目简介 -->
              <div class="div_text" v-html="block.bz"></div>
            </div>
            <!-- 选课走班 -->
            <div v-else-if="block.code === 'CourseSelection'" class="div_mod4">
              <!-- 栏目简介 -->
              <div class="div_text" v-html="block.bz"></div>
              <ul class="ul_nulist numCount">
                <!-- 栏目下的分类列表 -->
                <li v-for="subItem in blockChildren[index]" :key="subItem.id">
                  <!-- 添加数字加载动画 -->
                  <span
                    class="numCX"
                    data-decimals="0"
                    :data-endval="getStrNumberAndUnit(subItem?.bz)[0] || 0"
                    data-speed="4"
                    data-startval="0"
                    :id="subItem.id"
                  >
                    {{ getStrNumberAndUnit(subItem?.bz)[0] }} </span
                  ><b>{{ getStrNumberAndUnit(subItem?.bz)[1] }}</b>
                  <p>{{ subItem.title }}</p>
                </li>
              </ul>
            </div>
            <!-- 师资团队 -->
            <div v-else-if="block.code === 'Faculty'" class="div_mod5">
              <!-- 栏目下的分类列表 -->
              <ul class="ul_nulist numCount">
                <li v-for="subItem in blockChildren[index]" :key="subItem.id">
                  <span
                    class="numCX"
                    data-decimals="0"
                    :data-endval="getStrNumberAndUnit(subItem.bz)[0] || 0"
                    data-speed="4"
                    data-startval="0"
                    :id="subItem.id"
                  >
                    {{ getStrNumberAndUnit(subItem.bz)[0] }}
                  </span>
                  <b>{{ getStrNumberAndUnit(subItem.bz)[1] }}</b>
                  <p>{{ subItem.title }}</p>
                </li>
              </ul>
            </div>
            <!-- 其他 -->
            <div v-else>
              <dl
                :class="{
                  dl_state2: index % 2 !== 0,
                }"
              >
                <dt>
                  <!-- 栏目图片 -->
                  <img :src="block.bgimg" alt="" />
                </dt>
                <dd>
                  <!-- 栏目简介 -->
                  <div class="div_text" v-html="block.bz"></div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
// import portalApi from '../../../lvya-common/src/api/eos/portal'
import TabPageLayout from './components/TabPageLayout.vue'
import { useGetCategories } from './utils/useGetCategories'
import { mapState } from 'vuex'
import { useCountAnimation } from './utils/useCountAnimation'

export default {
  components: { TabPageLayout },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return (
        this.nav?.find((item) => item.code === 'yujianyizhong')?.stypes[0]
          ?.id || undefined
      )
    },
  },
  data() {
    return {
      portalSchoolInfo: null,
      blocks: [],
      blockChildren: [],
      numCxActiveArr: [],
    }
  },
  methods: {
    getStrNumberAndUnit(htmlStr) {
      if (!htmlStr) return []
      const content = htmlStr.match(/<p.*?>([\s\S]*?)<\/p>/)[1]
      if (content) {
        const count = content.match(/\d+/)[0]
        const unit = content.match(/[^\d]+$/)[0]
        return [count, unit]
      }
      return [0, '位']
    },
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      this.blocks = categories.list
    },
    async getBlockChildren() {
      for (const block of this.blocks) {
        const children = await useGetCategories(block.id)
        this.blockChildren.push(children.list)
      }
    },
    addNumberAnimation() {
      const numCx = document.querySelectorAll('.numCX')
      if (this.numCxActiveArr.length === numCx.length) {
        window.removeEventListener('scroll', null)
        return
      }
      window.addEventListener('scroll', () => {
        numCx.forEach((item) => {
          const id = item.getAttribute('id')
          if (this.numCxActiveArr.includes(id)) {
            return
          }
          const endVal = item.getAttribute('data-endVal')
          // 滚动到可视区域内时才执行动画，且只执行一次
          if (
            !this.numCxActiveArr.includes(id) &&
            item.getBoundingClientRect &&
            item.getBoundingClientRect().top < window.innerHeight
          ) {
            useCountAnimation(id, endVal)
            this.numCxActiveArr.push(id)
          }
        })
      })
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getBlockChildren()

    this.numCxActiveArr = []
    this.addNumberAnimation()
  },
  created() {
    this.portalSchoolInfo = JSON.parse(
      localStorage.getItem('portalSchoolInfo')
    )
  },
  beforeDestroy() {
    window.removeEventListener('scroll', null)
  }
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/about.css";
</style>
