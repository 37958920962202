import './requestAnimationFrame.polyfill'
import { CountUp } from './count'

// 数字动画
export function useCountAnimation(id, endVal) {
  const countUp = new CountUp(id, endVal, {
    useGrouping: true,
    separator: '',
  })
  countUp.start()
  return countUp
}
